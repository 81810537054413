var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-herder"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"footer-flex"},[_vm._m(2),_c('div',{staticClass:"aboundus"},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/certificate')}}},[_vm._v("营业执照")]),_c('span')]),_c('div',{staticClass:"aboundus"},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.Abfun('tw')}}},[_vm._v("软著展示")]),_c('span')])]),_c('div',{staticClass:"footer-flex",staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"width":"100%"}},[_c('icon-svg',{attrs:{"iconClass":"weixin"}}),_vm._v(" 微信咨询 ")],1),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx.png"}}),_vm._m(3)])]),_c('div',{staticClass:"footer_footer"},[_c('div',{staticClass:"footer_footer_her"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/downAPP')}}},[_vm._v(" 版本更新 ")]),_c('div',[_vm._v(" 支持社区 ")]),_c('div',{on:{"click":function($event){return _vm.$router.push('/cooperation')}}},[_vm._v(" 渠道合作 ")]),_c('div',{on:{"click":_vm.KFfun}},[_vm._v(" 开放平台 ")]),_c('div',[_vm._v(" 服务协议 ")]),_c('div',{on:{"click":function($event){return _vm.abus('on')}}},[_vm._v(" 关于我们 ")]),_c('div',{staticStyle:{"border":"none"},on:{"click":function($event){return _vm.abus('fo')}}},[_vm._v(" 联系我们 ")])]),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-flex"},[_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("联系我们")]),_c('span')]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("工作时间:")]),_c('span',{staticClass:"text"},[_vm._v("09:00 - 17:30")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("服务热线:")]),_c('span',{staticClass:"text"},[_vm._v("4006182019")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("QQ售前:")]),_c('span',{staticClass:"text"},[_vm._v("472720628")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("QQ投诉通道:")]),_c('span',{staticClass:"text"},[_vm._v("472720628")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-flex"},[_c('div',{staticClass:"aboundus"},[_c('span',{staticStyle:{"width":"90%","font-size":"12px"}},[_vm._v("拨打专线更快响应哦")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("渠道合作:")]),_c('span',{staticClass:"text"},[_vm._v("021-56029889")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("市场推广:")]),_c('span',{staticClass:"text"},[_vm._v("021-56029889")])]),_c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("连锁客户:")]),_c('span',{staticClass:"text"},[_vm._v("021-56029889")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboundus"},[_c('span',[_vm._v("资质证照")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"width":"100%"}},[_vm._v("微信扫一扫或者"),_c('br'),_vm._v("搜索“大总管”")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","margin-top":"2%","font-size":"12px"}},[_c('p',[_vm._v("2021-2021 © Copyright. DaZongGuan - All Rights Reserved")]),_c('p',[_vm._v("大总管（上海）科技有限公司版权所有 "),_c('a',{staticStyle:{"color":"#9B9B9B","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/recordQuery"}},[_vm._v("沪ICP备2021022797号-1")])]),_c('p',[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/beian.png"}}),_vm._v(" "),_c('a',{staticStyle:{"color":"#9B9B9B","text-decoration":"none"},attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006465","target":"_blank"}},[_vm._v("沪公网安备 31010602006465 号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone_footer"},[_c('div',{staticStyle:{"text-align":"center","font-size":"12px"}},[_c('p',[_vm._v("2021-2021 © Copyright. DaZongGuan - All Rights Reserved")]),_c('p',[_vm._v("大总管（上海）科技有限公司版权所有 "),_c('a',{staticStyle:{"color":"#9B9B9B","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/recordQuery"}},[_vm._v("沪ICP备2021022797号-1")])]),_c('p',[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/beian.png"}}),_vm._v(" "),_c('a',{staticStyle:{"color":"#9B9B9B","text-decoration":"none"},attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006465"}},[_vm._v("沪公网安备 31010602006465 号")])])])])
}]

export { render, staticRenderFns }