import { render, staticRenderFns } from "./FOOTER.vue?vue&type=template&id=f98c3934&scoped=true"
import script from "./FOOTER.vue?vue&type=script&lang=js"
export * from "./FOOTER.vue?vue&type=script&lang=js"
import style0 from "./FOOTER.vue?vue&type=style&index=0&id=f98c3934&prod&scoped=scoped&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f98c3934",
  null
  
)

export default component.exports